import React, { PureComponent } from 'react';

import Circle from '../atoms/Circle/Circle';

import CheckIcon from '@public/icons/check.svg';

type CardSubscriptionCircleProps = {
  backgroundColor: string;
};

class CardSubscriptionCircle extends PureComponent<CardSubscriptionCircleProps> {
  render() {
    return (
      <Circle backgroundColor={this.props.backgroundColor} size={3.375}>
        <CheckIcon width={32} height={32} style={{ color: '#ffffff' }} />
      </Circle>
    );
  }
}

export default CardSubscriptionCircle;
