import React, { PureComponent } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';

import { Colors } from '@shared/interfaces/themes';
import { Transform } from '@shared/enums/transform';

type CardSubscriptionLabelProps = {
  className: string;
  backgroundColor: keyof Colors;
  text: string;
};

class CardSubscriptionLabel extends PureComponent<CardSubscriptionLabelProps> {
  render() {
    return (
      <Box
        className={this.props.className}
        backgroundColor={this.props.backgroundColor}
      >
        <Text
          size={1.375}
          weight={600}
          transform={Transform.Uppercase}
          color="white"
          lineHeight="normal"
        >
          <span dangerouslySetInnerHTML={{ __html: this.props.text }} />
        </Text>
      </Box>
    );
  }
}

const StyledCardSubscriptionLabel = styled(CardSubscriptionLabel)`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: center;
  position: absolute;
  top: -1.375em;
  width: 16.125em;
  left: -0.0625em;
  right: -0.0625em;
  z-index: 1;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;

  :after {
    content: '';
    position: absolute;
    left: 45%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 0.875em solid transparent;
    border-right: 0.875em solid transparent;
    border-top: 0.875em solid
      ${props => props.theme.colors[props.backgroundColor]};
    clear: both;
  }
`;

export default StyledCardSubscriptionLabel;
