import React, { PureComponent } from 'react';

import Text from '../atoms/Text/Text';

import { Colors } from '@shared/interfaces/themes';

type CardSubscriptionPriceProps = {
  color: keyof Colors;
  size: number;
  price: string;
};

class CardSubscriptionPrice extends PureComponent<CardSubscriptionPriceProps> {
  render() {
    const priceArray = this.props.price.split('.');

    return (
      <Text size={1.125} color={this.props.color} verticalAlign="middle">
        <Text size={this.props.size}>{priceArray[0]}</Text>.{priceArray[1]}
      </Text>
    );
  }
}

export default CardSubscriptionPrice;
