import React, { PureComponent } from 'react';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';

import { Colors } from '@shared/interfaces/themes';

type PurchaseTypeProps = {
  color: keyof Colors;
  text: string;
};

class PurchaseType extends PureComponent<PurchaseTypeProps> {
  render() {
    const { color, text } = this.props;

    return (
      <Box mb={1}>
        <Text size={0.75} color={color}>
          {text}
        </Text>
      </Box>
    );
  }
}

export default PurchaseType;
