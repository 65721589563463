import React, { PureComponent } from 'react';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';
import Tooltip from '../molecules/Tooltip';

import { Align } from '@shared/enums/align';
import { Colors } from '@shared/interfaces/themes';

type CardSubscriptionInfoProps = {
  color: keyof Colors;
  name: string;
  title: string;
  subtitle: string;
  description: string;
  platforms: string;
  tooltip?: string;
};

class CardSubscriptionInfo extends PureComponent<CardSubscriptionInfoProps> {
  render() {
    const { color, title, name, description, platforms, subtitle } = this.props;

    return (
      <Box align={Align.Center}>
        <Box mb={0.625}>
          <Text size={2} color={color} lineHeight="normal">
            {name}
          </Text>
        </Box>
        <Box mb={0.875}>
          <Tooltip
            text={subtitle}
            textColor={color}
            title={title}
            platforms={platforms}
          />
        </Box>
        <Box mb={0.875}>
          <Text color={color} size={0.875} lineHeight="normal">
            {description}
          </Text>
        </Box>
      </Box>
    );
  }
}

export default CardSubscriptionInfo;
